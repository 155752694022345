import { Component } from '@angular/core';
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = environment.applicationTitle;
}

declare var $: any;

$(function() { // let all dom elements load
  $(document).on(
    'show.bs.collapse',
    '.accordion',
    function(e) {
      $(this)
        .find('.dropdown-arrow')
        .removeClass('flaticon-down-arrow')
        .addClass('flaticon-up-arrow');
  });

  $(document).on(
    'hide.bs.collapse',
    '.accordion',
    function(e) {
      $(this)
        .find('.dropdown-arrow')
        .removeClass('flaticon-up-arrow')
        .addClass('flaticon-down-arrow');
  });

});
