import {Injectable} from '@angular/core';
import {User} from "../entities/user";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: User = {
    lastMapStyle: ''
  };

  constructor() {
    this.loadUser();
  }

  loadUser(): User {
    const user = localStorage.getItem('user');
    if (user && user !== '') {
      this.user = JSON.parse(user);
      return this.user;
    }
  }

  saveUser() {
     localStorage.setItem('user', JSON.stringify(this.user));
  }

  getValue(key: string): any {
    if (this.user[key]) {
      return this.user[key];
    } else {
      return null;
    }

  }

  setValue(key: string, value: any) {
    if (this.user[key]) {
      this.user[key] = value;
      this.saveUser();
    }
  }

}
