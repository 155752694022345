import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapComponent } from './components/map/map.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { TaxonListComponent } from './components/taxon-list/taxon-list.component';
import {RouterModule, Routes} from "@angular/router";
import {HttpClientModule} from "@angular/common/http";
import { TaxonShowComponent } from './components/taxon-show/taxon-show.component';
import {FormsModule} from "@angular/forms";

import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { MediaViewerComponent } from './components/media-viewer/media-viewer.component';
import { AngularImageViewerModule } from "angular-x-image-viewer";
import { ConsolePipe } from './pipes/console.pipe';

const appRoutes: Routes = [
  {
    path: 'taxons',
    component: TaxonListComponent
  },
  {
    path: 'taxon/show/:taxonId',
    component: TaxonShowComponent
  },

  {
    path: 'taxon/show/:taxonId/:version',
    component: TaxonShowComponent
  },
  {
    path: '**',
    redirectTo: 'taxons'
  }
];

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    NavigationComponent,
    TaxonListComponent,
    TaxonShowComponent,
    MediaViewerComponent,
    ConsolePipe
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {enableTracing: false} // <-- debugging purposes only
    ),
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    AutocompleteLibModule,
    AngularImageViewerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
