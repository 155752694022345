
  <div *ngIf="image !== null && image !== undefined" class="bg-black-blur-100 position-fixed" style="width: 100%; height: 100%; z-index: 999999999999999999; top: 0; left: 0;">
    <angular-image-viewer
      [config]="config"
      [src]="[image]"
    ></angular-image-viewer>

    <div class="fixed-top bg-black-blur-100 w-100" style="height: 50px; line-height: 50px;">
      <div class="text-right">
        <div class="btn-group m-2" role="group" aria-label="Image controls">
          <button (click)="fitScreenSize()" type="button" class="btn btn-secondary bg-transparent border-0 px-4">100%</button>
          <button (click)="zoom(100)" type="button" class="btn btn-secondary bg-transparent border-0 px-4">Fit</button>
          <button (click)="closeImageViewer()" id="btn-close-media-viewer" type="button" class="btn btn-secondary bg-transparent border-0 px-4">Close</button>
        </div>
      </div>
    </div>


  </div>
