import {Injectable} from '@angular/core';
import * as mapboxgl from "mapbox-gl";
import {LngLatBounds} from "mapbox-gl";
import {environment} from "../../environments/environment";
import {Taxon} from "../entities/taxon";
import {Marker} from "../entities/marker";
import {Location} from "../entities/taxon/location";
import * as $ from 'jquery'
import {TaxonService} from "./taxon.service";
import {UserService} from "./user.service";
import { DeviceService } from './device.service';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  darkmode: boolean = false;
  mapStyles: {
    satellite: string,
    terrain: string,
    dark: string
  } = {
    satellite: 'mapbox://styles/mapbox/satellite-v9',
    terrain: 'mapbox://styles/dustin-von-gehlen/ck49toj6g0rln1cphrsunyq4g',
    dark: 'mapbox://styles/mapbox/dark-v10'
  };
  mapstyle: string = this.mapStyles.terrain;
  bounds: LngLatBounds = new mapboxgl.LngLatBounds();
  map: mapboxgl.Map = null;
  markers: Marker[] = [];
  mapMarkers: mapboxgl.Marker[] = [];
  maxZoom: number = 15;
  selectedLocationData: Location = null;

  constructor(
    readonly user: UserService,
    readonly device: DeviceService
  ) {
    this.darkmode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  init() {
    // @ts-ignore
    mapboxgl.maxParallelImageRequests = 16;
    // @ts-ignore
    mapboxgl.accessToken = environment.mapboxAccessToken;

    const userLastMapStyle = this.user.getValue('lastMapStyle');
    if (userLastMapStyle === '') {
      this.mapstyle = this.mapStyles.terrain;
      if (typeof this.darkmode != 'undefined' && this.darkmode) {
        this.mapstyle = this.mapStyles.dark;
      }
    } else {
      if (Object.keys(this.mapStyles).includes(userLastMapStyle)) {
        this.mapstyle = this.mapStyles[userLastMapStyle];
      }

    }



    this.map = new mapboxgl.Map({
      container: 'map',
      center: [-16.775312, 28.114479],
      style: this.mapstyle,
      zoom: 8
    });

    this.map.on('load', function() {
      // @ts-ignore
      //$('[class^=mapboxgl-ctrl-bottom-]').remove();
    });
  }

  panToMarker(location: Location) {

    const markerId = 'marker-' + location.id;
      let markerObject = document.getElementById(markerId);

      if (markerObject) {
        this.showMarkerDetails(markerObject);
        let latitude = markerObject.getAttribute('data-latitude');
        let longitude = markerObject.getAttribute('data-longitude');

        if (this.device.isMobile()) {
          window.scrollTo(0, 0);
        }

        if (latitude && longitude) {
          this.map.flyTo({
            // @ts-ignore
            center: [
              longitude,
              latitude
            ],
            zoom: this.maxZoom,
            maxZoom: this.maxZoom,
            padding: this.device.isMobile() ? 100 : 300,
            offset: [this.device.isMobile() ? 0 : 150, 0],
            essential: true // this animation is considered essential with respect to prefers-reduced-motion
          });

          window.location.hash = 'location-' + location.id;
        }
      }

  }

  toggleMapStyle() {
    const mapStyle = this.mapstyle === this.mapStyles.satellite ? this.mapStyles.terrain : this.mapStyles.satellite;
    this.mapstyle = mapStyle;
    this.map.setStyle(mapStyle);
    this.user.setValue('lastMapStyle', mapStyle);

  }


  changeMapStyle(style) {
    if (this.mapStyles[style]) {
      this.mapstyle = this.mapStyles[style];
      this.map.setStyle(this.mapStyles[style]);
      this.user.setValue('lastMapStyle', style);
    }
  }

  clearMarkers() {

    for (let removeMarker of this.mapMarkers) {
      removeMarker.remove();
    }
    this.mapMarkers = [];
    this.markers = [];

  }

  showLocationsOnMap(taxon: Taxon) {
    this.clearMarkers();
    this.bounds = new mapboxgl.LngLatBounds();

    if (taxon?.locations?.length > 0) {

      for (let location of taxon?.locations) {
        if (location?.latitude !== 0 && location?.longitude !== 0) {
          const marker = new Marker();
          marker.latitude = location.latitude;
          marker.longitude = location.longitude;
          marker.altitudeFrom = location.altitudeFrom;
          marker.altitudeTo = location.altitudeTo;

          marker.id = location.id;
          marker.classNames.push('marker-default');
          if (location.locusTypicus === 1) {
            marker.classNames.push('marker-LT');
          }
          if (location.dnaSequenceCO1 !== null) {
            marker.classNames.push('marker-CO1');
          }
          marker.location = location;

          marker.callbackFunction = (event) => {
            this.showMarkerDetails(event.target);
          };

          this.markers.push(marker);

          // @ts-ignore
          this.bounds.extend([marker.longitude, marker.latitude]);

          // add marker the map
          let mapboxMarker = new mapboxgl.Marker(marker.getDiv())
            .setLngLat([
              location.longitude,
              location.latitude
            ])
            .addTo(this.map);

          this.mapMarkers.push(mapboxMarker);
        }

      }

      this.fitBounds()
    }
  }

  showMarkerDetails(markerObject) {
    this.selectedLocationData = $(markerObject).data('location');
    $('.marker').removeClass('marker-attend');
    $(markerObject).toggleClass('marker-attend');
    window.location.hash = 'location-' + $(markerObject).data('location').id;
  }

  fitBounds(offsetTop: number = 0, offsetLeft: number = 150, padding: number = 300) {
    this.map.fitBounds(
      this.bounds,
      {
        maxZoom: this.maxZoom,
        padding: this.device.isMobile() ? 100 : padding,
        offset: [
          this.device.isMobile() ? 0 : offsetLeft,
          this.device.isMobile() ? 0 : offsetTop
        ]
      }
    );
  }

}
