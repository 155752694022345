import {Component, Input, OnInit} from '@angular/core';
import {ImageViewerConfig} from 'angular-x-image-viewer';


@Component({
  selector: 'app-media-viewer',
  templateUrl: './media-viewer.component.html',
  styleUrls: ['./media-viewer.component.css']
})
export class MediaViewerComponent implements OnInit {

  @Input() image?: string = null;

  config: ImageViewerConfig = {
    wheelZoom: true,
    containerBackgroundColor: 'rgba(0,0,0,0)',
    allowFullscreen: false,
    btnShow: {
      next: false,
      prev: false,
      rotateClockwise: false,
      rotateCounterClockwise: false,
      zoomIn: false,
      zoomOut: false
    }
  };

  constructor() { }

  ngOnInit(): void {
    $(() => {
      $('.img-container').on('click', () => {
        this.closeImageViewer();
      })
    })
  }

  zoom(scale: number) {
    $('.img-container img').css({
      'transform': 'scale(' + (scale / 100) + ')',
      'width': '',
      'height': '',
      'max-width': '90%',
      'max-height': '95%',
    })
  }

  fitScreenSize() {
    const image = $('.img-container img');
    const imageSize = this.getImgeSize('.img-container img')

    $(image).css({
      'transform': '',
      'width': imageSize.width,
      'height': imageSize.height,
      'max-width': imageSize.width,
      'max-height': imageSize.height,
    })
  }

  closeImageViewer() {
    this.image = undefined;
  }

  getImgeSize(selector: any): { width: number, height: number } {
    const image: HTMLImageElement = document.querySelector(selector);

    return {
      width: image.naturalWidth,
      height: image.naturalHeight
    }

  }

}
