<app-media-viewer [image]="selectedImageUrl"></app-media-viewer>

<div class="spread-top d-block d-sm-none"></div>

<div class="map-overlay mt-5 w-100" [class.d-none]="!taxonNotFound">
  <div class="bg-black-blur-100 rounded shadow-danger-lg p-3 text-center bg-danger">
    Unfortunately, this entry was not found.
  </div>
</div>

<div class="content-container pl-1 pr-1 pl-md-3 pl-sm-3 pt-3 map-overlay map-overlay-hideable col-lg-4 col-md-4 col-sm-6 mw-sm">

  <div *ngIf="taxon !== null">

    <a class="d-block d-sm-none arrow-icon open">
      <span class="left-bar"></span>
      <span class="right-bar"></span>
    </a>

    <!-- Figures -->
    <section *ngIf="figuresCount > 0" class="accordion shadow-dark-lg mb-3" id="accordionFigures">
      <div class="card bg-black-blur-100">

          <figure class="figure-container text-center" *ngIf="figuresCollection?.habitus?.length > 0">
            <img
              class="figure-top-image cursor-pointer mt-4 mr-2"
              [attr.src]="environment.cdnBaseUrl + '/' + figuresCollection?.habitus[0]?.file"
              [alt]="figuresCollection?.habitus[0]?.title"
              (click)="previewFigure(figuresCollection?.habitus[0])"
            >
          </figure>

        <div class="card-header cursor-pointer" id="headingFigures" data-toggle="collapse" data-target="#collapseFigures" aria-expanded="false" aria-controls="collapseFigures">
          <div class="row">
            <div class="col-9">
              <h5 class="m-0" >
                Figures
              </h5>
            </div>
            <div class="col-3 text-right align-middle">
              <i class="dropdown-arrow flaticon-down-arrow"></i>
            </div>
          </div>
        </div>

        <div id="collapseFigures" class="collapse" aria-labelledby="headingFigures" data-parent="#accordionFigures">
          <div class="card-body border-0 pt-0">
            <hr class="mt-0">

            <figure class="figure-container" *ngIf="figuresCollection?.habitus?.length - 1 > 0">
              <figcaption>Habitus</figcaption>
              <ng-container *ngFor="let file of figuresCollection?.habitus; let i = index">
                <img *ngIf="i > 0"
                  class="figure-image cursor-pointer mt-2 mr-2"
                  [attr.src]="environment.cdnBaseUrl + '/' + file.file"
                  [alt]="file.title"
                  (click)="previewFigure(file)"
                  loading="lazy"
                >
              </ng-container>
            </figure>

            <figure class="figure-container" *ngIf="figuresCollection?.genitalia?.length > 0">
              <figcaption>Genitalia</figcaption>
              <ng-container *ngFor="let file of figuresCollection?.genitalia">
                <img
                  class="figure-image cursor-pointer mt-2 mr-2"
                  [attr.src]="environment.cdnBaseUrl + '/' + file.file"
                  [alt]="file.title"
                  (click)="previewFigure(file)"
                  loading="lazy"
                >
              </ng-container>
            </figure>

            <figure class="figure-container" *ngIf="figuresCollection?.identification?.length > 0">
              <figcaption>Identification</figcaption>
              <ng-container *ngFor="let file of figuresCollection?.identification">
                <img
                  class="figure-image cursor-pointer mt-2 mr-2"
                  [attr.src]="environment.cdnBaseUrl + '/' + file.file"
                  [alt]="file.title"
                  (click)="previewFigure(file)"
                  loading="lazy"
                >
              </ng-container>
            </figure>

            <figure class="figure-container" *ngIf="figuresCollection?.biology?.length > 0">
              <figcaption>Biology</figcaption>
              <ng-container *ngFor="let file of figuresCollection?.biology">
                <img
                  class="figure-image cursor-pointer mt-2 mr-2"
                  [attr.src]="environment.cdnBaseUrl + '/' + file.file"
                  [alt]="file.title"
                  (click)="previewFigure(file)"
                  loading="lazy"
                >
              </ng-container>
            </figure>

            <figure class="figure-container" *ngIf="figuresCollection?.dispersalArea?.length > 0">
              <figcaption>Dispersal area</figcaption>
              <ng-container *ngFor="let file of figuresCollection?.dispersalArea">
                <img
                  class="figure-image cursor-pointer mt-2 mr-2"
                  [attr.src]="environment.cdnBaseUrl + '/' + file.file"
                  [alt]="file.title"
                  (click)="previewFigure(file)"
                  loading="lazy"
                >
              </ng-container>
            </figure>

          </div>
        </div>
      </div>
    </section>


    <!-- Taxonomic data -->
    <section class="accordion shadow-dark-lg mb-3" id="accordionTaxonomicData">
      <div class="card bg-black-blur-100">
        <div class="card-header cursor-pointer" id="headingTaxonomicData" data-toggle="collapse" data-target="#collapseTaxonomicData" aria-expanded="false" aria-controls="collapseTaxonomicData">
          <div class="row">
            <div class="col-9">
              <h5 class="m-0" >
                Taxonomic data
              </h5>
            </div>
            <div class="col-3 text-right align-middle">
              <i class="dropdown-arrow flaticon-down-arrow"></i>
            </div>
          </div>
        </div>

        <div id="collapseTaxonomicData" class="collapse" aria-labelledby="headingTaxonomicData" data-parent="#accordionTaxonomicData">
          <div class="card-body border-0 pt-0">
            <hr class="mt-0">

            <dl *ngIf="!isNothing(taxon?.family)" class="row mb-0">
              <dt class="col">Family</dt>
              <dd class="col">{{ taxon?.family }}</dd>
            </dl>
            <dl *ngIf="!isNothing(taxon?.subFamily)" class="row mb-0">
              <dt class="col">Subfamily</dt>
              <dd class="col">{{ taxon?.subFamily }}</dd>
            </dl>
            <dl *ngIf="!isNothing(taxon?.tribe)" class="row mb-0">
              <dt class="col">Tribe</dt>
              <dd class="col">{{ taxon?.tribe }}</dd>
            </dl>
            <dl *ngIf="!isNothing(taxon?.subTribe)" class="row mb-0">
              <dt class="col">Subtribe</dt>
              <dd class="col">{{ taxon?.subTribe }}</dd>
            </dl>
            <dl *ngIf="!isNothing(taxon?.genus)" class="row mb-0">
              <dt class="col">Genus</dt>
              <dd class="col">{{ taxon?.genus }}</dd>
            </dl>
            <dl *ngIf="!isNothing(taxon?.subGenus)" class="row mb-0">
              <dt class="col">Subgenus</dt>
              <dd class="col">{{ taxon?.subGenus }}</dd>
            </dl>
            <dl *ngIf="!isNothing(taxon?.originalGenus)" class="row mb-0">
              <dt class="col">Original genus</dt>
              <dd class="col">{{ taxon?.originalGenus }}</dd>
            </dl>
            <dl *ngIf="!isNothing(taxon?.species)" class="row mb-0">
              <dt class="col">Species</dt>
              <dd class="col">{{ taxon?.species }}</dd>
            </dl>
            <dl *ngIf="!isNothing(taxon?.subSpecies)" class="row mb-0">
              <dt class="col">Subspecies</dt>
              <dd class="col">{{ taxon?.subSpecies }}</dd>
            </dl>

            <!-- Synonyms / Homonyms -->
            <div *ngIf="taxon?.synonyms?.length > 0">
              <h5 class="mt-5">Synonyms / Homonyms</h5>
              <hr>
              <dl *ngFor="let synonym of taxon?.synonyms" class="row mb-0">
                <dd class="col m-0">{{ synonym.synonym }}</dd>
              </dl>
            </div>

            <!-- Distribution -->
            <div *ngIf="taxon?.distribution !== null && taxon?.distribution !== ''">
              <h5 class="mt-5">Distribution</h5>
              <hr>
              <span>{{ taxon?.distribution }}</span>
            </div>

          </div>
        </div>
      </div>
    </section>



    <!-- Locations & DNA -->
    <section *ngIf="taxon?.locations?.length > 0" class="accordion mb-3 shadow-dark-lg" id="accordionLocations">
      <div class="card bg-black-blur-100">
        <div class="card-header border-0 cursor-pointer" id="headingLocations" data-toggle="collapse" data-target="#collapseLocations" aria-expanded="false" aria-controls="collapseLocations">
          <div class="row">
            <div class="col-9">
              <h5 class="m-0" >
                Locations & DNA
              </h5>
            </div>
            <div class="col-3 text-right align-middle">
              <i class="dropdown-arrow flaticon-down-arrow"></i>
            </div>
          </div>
        </div>

        <div id="collapseLocations" class="collapse" aria-labelledby="headingLocations" data-parent="#accordionLocations">
          <div class="card-body border-0 p-0">
            <div class="list-group p-0 m-0 mt-0 taxon-list bg-transparent border-0">
              <div *ngFor="let locationGroup of locationCollection | keyvalue">
                <div *ngIf="locationGroup.value.length > 0">
                  <div>
                    <h5 class="list-group-item m-0 pt-5 border-0">
                      {{ locationGroup.key === 'a_locusTypicus' ? 'Locus typicus' : null }}
                      {{ locationGroup.key === 'b_dnaSequences' ? 'DNA Sequences' : null }}
                      {{ locationGroup.key === 'c_other' ? 'Other locations' : null }}
                    </h5>
                  </div>
                  <a
                    *ngFor="let location of locationGroup.value"
                    (click)="map.panToMarker(location)"
                    [class.active]="selectedLocation === location"
                    class="list-group-item list-group-item-action border-bottom cursor-pointer rounded-0 border-left-0 border-right-0 pl-5"
                  >
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1">{{ location?.geoCountry }}</h5>
                      <small>{{ location?.quantity | number }} Ex.</small>
                    </div>
                    <p class="mb-1">{{ nomenclature.getLocationCaption(location) }}</p>
                    <div class="row mt-3">
                      <small
                              *ngIf="location?.plant !== null"
                              class="col col-sm-6"
                      >
                        <i class="flaticon-plant icon-16 mr-3"></i>{{ location?.plant }}
                      </small>
                      <small
                              *ngIf="location?.altitudeFrom && location?.altitudeFrom > 0 || location?.altitudeTo && location?.altitudeTo > 0"
                              class="col col-sm-6"
                      >
                        <i class="flaticon-altitude icon-16 mr-3"></i>
                        <span>{{ nomenclature.getAltitude(location) }}</span>
                      </small>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <span *ngIf="location?.dnaSequenceCO1 !== '' && location?.dnaSequenceCO1 !== null" class="badge badge-CO1 pl-2 pr-2 mr-3">CO1</span>
                        <span *ngIf="location?.dnaSequence16S !== '' && location?.dnaSequence16S !== null" class="badge badge-16S pl-2 pr-2 mr-3">16S</span>
                        <span *ngIf="location?.dnaSequence28S !== '' && location?.dnaSequence28S !== null" class="badge badge-28S pl-2 pr-2 mr-3">28S</span>
                        <span *ngIf="location?.locusTypicus === 1" class="badge badge-LT pl-2 pr-2 mr-3">Locus typicus</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>



    <!-- Habitats & Plants -->
    <section *ngIf="plantStatistics?.statisticsSorted?.length > 0" class="accordion mb-3 shadow-dark-lg" id="accordionPlantStatistics">
      <div class="card bg-black-blur-100">
        <div class="card-header border-0 cursor-pointer" id="headingPlantStatistics" data-toggle="collapse" data-target="#collapsePlantStatistics" aria-expanded="false" aria-controls="collapsePlantStatistics">
          <div class="row">
            <div class="col-9">
              <h5 class="m-0" >
                Habitats & Plants
              </h5>
            </div>
            <div class="col-3 text-right align-middle">
              <i class="dropdown-arrow flaticon-down-arrow"></i>
            </div>
          </div>
        </div>

        <div id="collapsePlantStatistics" class="collapse" aria-labelledby="headingPlantStatistics" data-parent="#accordionPlantStatistics">
          <div class="card-body border-0 pt-0">
            <ul class="list-group p-0 mt-0 taxon-list bg-transparent">
              <li
                      *ngFor="let plantStatistic of plantStatistics.getPlantsByQuantity()"
                      class="list-group-item border-bottom rounded-0 border-left-0 border-right-0"
              >
                <div class="d-flex w-100 justify-content-between mb-2">
                  <h5 class="mb-1">{{ plantStatistic[0] }}</h5>
                  <small>{{ plantStatistic[1] | number }} Ex.</small>
                </div>
                <div class="progress bg-transparent" style="height: 5px">
                  <div class="progress-bar rounded bg-success" role="progressbar" [style.width]="(plantStatistic[1] / plantStatistics.maxQuantity * 100) + '%'" [attr.aria-valuenow]="(plantStatistic[1] / plantStatistics.maxQuantity * 100)" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>



    <!-- Literature -->
    <section *ngIf="taxon?.literature !== null && taxon?.literature?.length > 0" class="accordion mb-3 shadow-dark-lg" id="accordionLiterature">
      <div class="card bg-black-blur-100">
        <div class="card-header border-0 cursor-pointer" id="headingLiterature" data-toggle="collapse" data-target="#collapseLiterature" aria-expanded="false" aria-controls="collapseLiterature">
          <div class="row">
            <div class="col-9">
              <h5 class="m-0" >
                Literature
              </h5>
            </div>
            <div class="col-3 text-right align-middle">
              <i class="dropdown-arrow flaticon-down-arrow"></i>
            </div>
          </div>
        </div>

        <div id="collapseLiterature" class="collapse" aria-labelledby="headingLiterature" data-parent="#accordionLiterature">
          <div class="card-body border-0 p-0">
            <div *ngIf="literatureCollection?.firstDescriptions?.length > 0">
              <hr class="mt-0">
              <h5 class="m-3 pt-5">First description</h5>
              <div class="list-group p-0 m-0 taxon-list bg-transparent">
                <a
                        *ngFor="let literature of literatureCollection?.firstDescriptions"
                        (click)="previewLiterature(literature)"
                        class="list-group-item border-bottom rounded-0 border-left-0 border-right-0"
                        [class.list-group-item-action]="literature?.referenceType === 'INTERNAL' || literature?.referenceType === 'EXTERNAL'"
                        [class.cursor-pointer]="literature?.referenceType === 'INTERNAL' || literature?.referenceType === 'EXTERNAL'"
                >

                  <div class="d-flex w-100 justify-content-between">
                    <small>{{ literature?.publishedByName !== '' && literature?.publishedByName !== null ? literature?.publishedByName : '- Unknown author -'  }} {{ literature?.publishedByYear }}</small>
                    <span class="flaticon-link-external icon-16 ml-3" [class.d-none]="literature?.referenceType !== 'EXTERNAL'"></span>
                  </div>

                  <br>
                  <cite>{{ literature?.title !== '' && literature?.title !== null ? literature?.title : '- No title -' }}</cite>

                </a>
              </div>
            </div>

            <div *ngIf="literatureCollection?.furtherReferences?.length > 0">
              <h5 class="m-3 pt-5">Further references</h5>
              <div class="list-group p-0 mt-0 taxon-list bg-transparent">
                <a
                        *ngFor="let literature of literatureCollection?.furtherReferences"
                        (click)="previewLiterature(literature)"
                        class="list-group-item border-bottom rounded-0 border-left-0 border-right-0"
                        [class.list-group-item-action]="literature?.referenceType === 'INTERNAL' || literature?.referenceType === 'EXTERNAL'"
                        [class.cursor-pointer]="literature?.referenceType === 'INTERNAL' || literature?.referenceType === 'EXTERNAL'"
                >

                  <div class="d-flex w-100 justify-content-between">
                    <small>{{ literature?.publishedByName }} {{ literature?.publishedByYear }}</small>
                    <span class="flaticon-link-external icon-16 ml-3" [class.d-none]="literature?.referenceType !== 'EXTERNAL'"></span>
                  </div>

                  <br>
                  <cite>{{ literature?.title }}</cite>

                </a>
              </div>
            </div>


          </div>
        </div>
      </div>
    </section>



    <!-- Proposals & Comments -->
    <section *ngIf="taxon?.comments !== null || (taxon?.remarks !== null && taxon?.remarks?.length > 0)" class="accordion mb-3 shadow-dark-lg" id="accordionRemarks">
      <div class="card bg-black-blur-100">
        <div class="card-header border-0 cursor-pointer" id="headingRemarks" data-toggle="collapse" data-target="#collapseRemarks" aria-expanded="false" aria-controls="collapseRemarks">
          <div class="row">
            <div class="col-9">
              <h5 class="m-0" >
                Proposals & Comments
              </h5>
            </div>
            <div class="col-3 text-right align-middle">
              <i class="dropdown-arrow flaticon-down-arrow"></i>
            </div>
          </div>
        </div>

        <div id="collapseRemarks" class="collapse" aria-labelledby="headingRemarks" data-parent="#accordionRemarks">
          <div class="card-body border-0 p-0">
            <div class="list-group p-0 mt-0 taxon-list bg-transparent">
              <a
                      *ngFor="let remark of taxon?.remarks"
                      (click)="previewRemark(remark)"
                      class="list-group-item border-bottom rounded-0 border-left-0 border-right-0"
                      [class.list-group-item-action]="remark?.content === null && remark?.reference !== null && remark?.reference !== ''"
                      [class.cursor-pointer]="remark?.content === null && remark?.reference !== null && remark?.reference !== ''"
              >

                <div class="d-flex w-100 justify-content-between">
                  <small>{{ remark?.publishedByName }} {{ remark?.publishedByYear }}</small>
                  <span class="flaticon-link-external icon-16 ml-3" [class.d-none]="remark?.content !== null && remark?.reference === null && remark?.reference === ''"></span>
                </div>

                <br>
                <cite>{{ remark?.title }}</cite>

              </a>
            </div>

            <div class="p-3">
              <cite class="text-normal" [innerHTML]="taxon?.comments"></cite>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</div>


<!-- Map & other controls-->
<div class="map-overlay map-overlay-controls bg-black-blur-100 rounded shadow-dark-lg p-2 mt-0 ml-1 mr-1 mt-sm-3 mr-md-3 mr-sm-3">
  <div class="row m-0 p-0 mb-0">
    <div class="d-block d-sm-none col-2 m-0 p-0 mb-2">
      <button [routerLink]="['/taxons']" class="btn btn-map-control h-100">
        <i class="flaticon-left-arrow"></i>
      </button>

    </div>
    <div class="col-8 col-sm-12 col-md-12 col-lg-12 p-2 m-0 text-center text-md-right" [innerHTML]="nomenclature.getTaxonCaption(taxonService.taxon, true)"></div>
    <div class="d-block d-sm-none col-2 m-0 p-0 mb-2">
      <button class="btn btn-map-control h-100 float-right" data-toggle="collapse" data-target="#collapseMobileMenu" aria-expanded="false" aria-controls="collapseMobileMenu">
        <i class="flaticon-down-arrow"></i>
      </button>
    </div>
  </div>

  <div id="accordion3" class="row m-0 mt-0">

    <nav id="collapseMobileMenu" class="nav nav-pills nav-justified w-100 collapse border-top pt-2" [class.show]="!device.isMobile()" data-parent="#accordion3">
      <a [routerLink]="['/taxons']" class="nav-item nav-link btn-map-control cursor-pointer text-nowrap map-overlay-hideable d-none d-sm-block">
        <i class="flaticon-left-arrow"></i>
        <span class="d-none d-md-block">Back to species list</span>
      </a>
      <a (click)="toggleOverview()" class="nav-item nav-link btn-map-control cursor-pointer text-nowrap">
        <i [class.flaticon-eye-closed]="!overlaysHidden" [class.flaticon-eye-open]="overlaysHidden"></i>
        <span class="d-none d-md-block">Overview</span>
      </a>
      <a (click)="linkThisSite()" class="nav-item nav-link btn-map-control cursor-pointer text-nowrap map-overlay-hideable"
         data-toggle="popover"
         title="Link copied"
         data-content="The URL of this site has been copied to your clipboard."
         data-placement="bottom"
      >
        <i class="flaticon-link"></i>
        <span class="d-none d-md-block">Link this site</span>
      </a>
      <a class="nav-item nav-link btn-map-control cursor-pointer text-nowrap map-overlay-hideable" data-toggle="collapse" data-target="#collapseHistoricalData" aria-expanded="false" aria-controls="collapseHistoricalData">
        <i class="flaticon-clock"></i>
        <span class="d-none d-md-block">Historical data</span>
      </a>
    </nav>
  </div>

  <div class="row m-0 p-0">
    <div class="col m-0 p-0">
      <div id="accordion2">
          <div id="collapseHistoricalData" class="collapse m-0 overflow-auto mh-350" aria-labelledby="headingOne1" data-parent="#accordion2">

            <!-- Historical data list -->
            <div class="list-group p-0 taxon-list rounded w-100">
              <div
                *ngFor="let version of taxonService?.taxon?.versions; let index = index"
                (click)="changeVersion(version.version)"
                class="list-group-item list-group-item-action cursor-pointer w-100"
              >
                {{ version.dateCreated | date }} {{ taxonService?.taxon?.versions[0].version === version.version ? '(current)' : '' }}
              </div>

            </div>

          </div>
      </div>
    </div>
  </div>

</div>

<!-- Locational information dialogue (modal) -->
<div id="locationModal" class="modal fade bd-example-modal-lg " tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg bg-black-blur-100 rounded mb-3 shadow-dark-lg">
    <div class="modal-content bg-transparent text-white border-0">
      <div class="modal-header border-0 pb-0">
        <h5>Locational information</h5>
        <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pt-0 pb-0">
        <hr>
        <span *ngIf="this.map?.selectedLocationData?.locusTypicus === 1"><span class="badge badge-LT pl-2 pr-2 mb-2 mr-3 d-inline-block">Locus typicus</span><br></span>
        {{ this.map?.selectedLocationData?.geoCountry }}<br>
        <small>
          {{ this.map?.selectedLocationData | console }}
          {{ nomenclature.getGeoLocationCaption(this.map?.selectedLocationData) }}
        </small>
        <br>
        <div
          *ngIf="this.map?.selectedLocationData?.plant !== null"
          class="row mt-3"
        >
          <dt class="col-3">
            <i class="flaticon-plant icon-16 mr-3"></i>Plant
          </dt>

          <dd class="col-auto">
            {{ this.map?.selectedLocationData?.plant }}
          </dd>
        </div>
        <div

          class="row mt-3"
        >
          <dt class="col-3">
            <i class="flaticon-map-marker icon-16 mr-3"></i>GPS
          </dt>

          <dd class="col-auto">
            {{ this.map?.selectedLocationData?.latitudeDMS }}, {{ this.map?.selectedLocationData?.longitudeDMS }}
          </dd>
        </div>
        <div
          *ngIf="this.map?.selectedLocationData?.altitudeFrom && this.map?.selectedLocationData?.altitudeFrom > 0 ||
          this.map?.selectedLocationData?.altitudeTo && this.map?.selectedLocationData?.altitudeTo > 0"
          class="row mt-3"
        >
          <dt class="col-3">
            <i class="flaticon-altitude icon-16 mr-3"></i>Altitude
          </dt>

          <dd class="col-auto ">
            <span>{{ nomenclature.getAltitude(this.map?.selectedLocationData) }}</span>
          </dd>
        </div>

        <div
          *ngIf="this.map?.selectedLocationData?.altitudeFrom && this.map?.selectedLocationData?.altitudeFrom > 0 ||
          this.map?.selectedLocationData?.altitudeTo && this.map?.selectedLocationData?.altitudeTo > 0"
          class="row mt-3"
        >
          <dt class="col-3">
            <i class="flaticon-clock icon-16 mr-3"></i>Date
          </dt>

          <dd class="col-auto ">
            <span>{{ this.map?.selectedLocationData?.dateCreated | date : 'd.m.y' }}</span>
          </dd>
        </div>

        <div
          *ngIf="this.map?.selectedLocationData?.dnaSequenceCO1 !== null ||
              this.map?.selectedLocationData?.dnaSequence16S !== null ||
              this.map?.selectedLocationData?.dnaSequence28S !== null"
        >
          <hr>
          <h5>DNA sequence</h5>

          <div *ngIf="this.map?.selectedLocationData?.dnaSequenceCO1 !== null" class="row">
            <div class="col-1 mb-3">
              <button class="btn badge badge-CO1 mr-3">
                CO1
              </button>
            </div>
            <div class="col-11" [class.col-12]="!this.map?.selectedLocationData?.dnaSequenceCO1.startsWith('http')">
              <div class="" [innerHTML]="this.map?.selectedLocationData?.dnaSequenceCO1Formatted"></div>
            </div>
          </div>

          <div *ngIf="this.map?.selectedLocationData?.dnaSequence16S !== null" class="row">
            <div class="col-1 mb-3">
              <button class="btn badge badge-16S mr-3">
                16S
              </button>
            </div>
            <div class="col-11" [class.col-12]="!this.map?.selectedLocationData?.dnaSequence16S.startsWith('http')">
              <div class="" [innerHTML]="this.map?.selectedLocationData?.dnaSequence16SFormatted"></div>
            </div>
          </div>

          <div *ngIf="this.map?.selectedLocationData?.dnaSequence28S !== null" class="row">
            <div class="col-1 mb-3">
              <button class="btn badge badge-28S mr-3">
                28S
              </button>
            </div>
            <div class="col-11" [class.col-12]="!this.map?.selectedLocationData?.dnaSequence28S.startsWith('http')">
              <div class="" [innerHTML]="this.map?.selectedLocationData?.dnaSequence28SFormatted"></div>
            </div>
          </div>


<!--          <div id="accordion">-->
<!--            <div class="card bg-transparent border-0">-->
<!--              <div class="card-header p-0 bg-transparent border-0" id="headingOne">-->
<!--                <h5 class="mb-0">-->
<!--                  <button *ngIf="this.map?.selectedLocationData?.dnaSequenceCO1 !== null" class="btn badge badge-CO1 mr-3 cursor-pointer" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">-->
<!--                    CO1-->
<!--                  </button>-->
<!--                  <button *ngIf="this.map?.selectedLocationData?.dnaSequence16S !== null" class="btn badge badge-16S collapsed mr-3 cursor-pointer" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">-->
<!--                    16S-->
<!--                  </button>-->
<!--                  <button *ngIf="this.map?.selectedLocationData?.dnaSequence28S !== null" class="btn badge badge-28S collapsed mr-3 cursor-pointer" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">-->
<!--                    28S-->
<!--                  </button>-->
<!--                </h5>-->
<!--              </div>-->

<!--              <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">-->
<!--                <div class="card-body p-2" [innerHTML]="this.map?.selectedLocationData?.dnaSequenceCO1Formatted"></div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="card bg-transparent border-0">-->
<!--              <div class="card-header p-0 bg-transparent border-0" id="headingTwo">-->
<!--              </div>-->
<!--              <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">-->
<!--                <div class="card-body p-2" [innerHTML]="this.map?.selectedLocationData?.dnaSequence16SFormatted"></div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="card bg-transparent border-0">-->
<!--              <div class="card-header p-0 bg-transparent border-0" id="headingThree">-->
<!--              </div>-->
<!--              <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#accordion">-->
<!--                <div class="card-body p-2" [innerHTML]="this.map?.selectedLocationData?.dnaSequence28SFormatted"></div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>

        <div
          *ngIf="this.map?.selectedLocationData?.collectedBy !== null ||
                this.map?.selectedLocationData?.determinedBy !== null ||
                this.map?.selectedLocationData?.collection !== null"
        >
          <hr>
          <h5>Further information</h5>

          <span *ngIf="this.map?.selectedLocationData?.collectedBy !== null">leg. {{ this.map?.selectedLocationData?.collectedBy }}<br></span>
          <span *ngIf="this.map?.selectedLocationData?.determinedBy !== null">det. {{ this.map?.selectedLocationData?.determinedBy }}<br></span>
          <span *ngIf="this.map?.selectedLocationData?.collection !== null">coll. {{ this.map?.selectedLocationData?.collection }}<br></span>
        </div>


        <div *ngIf="this.map?.selectedLocationData?.comments !== null && this.map?.selectedLocationData?.comments !== ''">
          <hr>
          {{ this.map?.selectedLocationData?.comments }}
        </div>



      </div>
      <div class="modal-footer border-0 pt-0">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
