import {Injectable} from '@angular/core';
import { ApiService } from './api.service';
import { TaxonService } from './taxon.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  isSearchRunning: boolean = false;
  query: string = null;
  minQueryLength: number = 3;
  resultCount: number = null;

  constructor(
      readonly api: ApiService,
      readonly taxonService: TaxonService,
  ) {

  }

  search(query: string = '') {
    this.query = query.trim();
    if (query.length >= this.minQueryLength) {
      if (query !== '') {
        this.isSearchRunning = true;
        this.api.search(query).then(results => {
          if (results !== null) {
            this.taxonService.taxons = results;
            this.resultCount = this.taxonService.taxons.length;
            this.taxonService.groupTaxons();
          } else {
            this.taxonService.getTaxons().then(taxons => {

            });
            this.resultCount = 0;
            this.getTaxonsList()
          }
          this.isSearchRunning = false;
        }).catch(error => {
          this.isSearchRunning = false;
          this.resultCount = 0;
          this.getTaxonsList()
        });
      } else {
        this.getTaxonsList()
      }
    }
  }

  getTaxonsList() {
    this.taxonService.getTaxons().then(taxons => {
      this.taxonService.taxons = taxons;
      this.taxonService.groupTaxons();
      //this.resultCount = this.taxonService.taxons.length;
    });
  }

}
