<header>
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top shadow-dark-lg bg-black-blur-100">
    <a class="navbar-brand" [routerLink]="['/taxons']">
      <span class="d-none d-md-inline">
        {{ applicationTitle }} - {{ applicationSubTitle }}
      </span>
      <span class="d-md-none d-inline">
        {{ applicationTitle }}
      </span>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" [href]="'/taxons'">Species list</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [href]="environment.cdnBaseUrl + '/f13dae56-e985-4611-818c-68716bfd9d93'" target="_blank" rel="noopener">Scientific staff</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [href]="environment.cdnBaseUrl + '/799cb9ed-972d-4a21-b8cb-c391c59a94ce'" target="_blank" rel="noopener">Islands in progress</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [href]="environment.cdnBaseUrl + '/56513d44-6fab-49b6-b336-4c71fcaeb2f8'" target="_blank" rel="noopener">Introduction</a>
        </li>

      </ul>

      <form class="form-inline my-2 my-lg-0 mr-2">
        <input [disabled]="searchService.isSearchRunning" (keydown.enter)="search($event.target.value)" [value]="searchService.query" type="search" class="form-control search-box" placeholder="Search for species, authors, ...">
      </form>
      <div [class.d-none]="!searchService.isSearchRunning" class="spinner float-right">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>

    </div>
  </nav>
</header>
