import {Location} from "./taxon/location";

export class Marker {
  id: number = null;
  latitude: number = null;
  longitude: number = null;
  altitudeFrom: number = null;
  altitudeTo: number = null;
  classNames: string[] = ['icon-32', 'marker'];
  type: string = null;
  callbackFunction = null;
  location: Location = null;

  getClassNamesAsString(): string {
    return this.classNames.join(' ');
  }

  getDiv(): HTMLElement {
    let el = document.createElement('div');

    el.className = this.getClassNamesAsString();


    el.id = 'marker-' + this.id;
    el.setAttribute('data-longitude', this.longitude?.toString());
    el.setAttribute('data-latitude', this.latitude?.toString());
    el.setAttribute('data-altitudeFrom', this.altitudeFrom?.toString());
    el.setAttribute('data-altitudeTo', this.altitudeTo?.toString());
    el.setAttribute('data-markerType', this.type);
    el.setAttribute('data-toggle', 'modal');
    el.setAttribute('data-target', '#locationModal');
    el.setAttribute('data-location', JSON.stringify(this.location));


    el.addEventListener('click', this.callbackFunction);

    return el;
  }
}
