import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../environments/environment';

@Pipe({
  name: 'console'
})
export class ConsolePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (environment.debug) {
      console.log(value);
    }
    return null;
  }

}
