import { Injectable } from '@angular/core';
// @ts-ignore
import {Taxon} from '../entities/taxon';
import { Location } from '../entities/taxon/location';

@Injectable({
  providedIn: 'root'
})
export class NomenclatureService {

  constructor() { }

  getGeoLocationCaption(location: Location): string {
    const parts = [];

    if (this.isValueSet(location?.geoCity)) { parts.push(location?.geoCity); }
    if (this.isValueSet(location?.geoRegion)) { parts.push(location?.geoRegion); }
    if (this.isValueSet(location?.geoSpot)) { parts.push(location?.geoSpot); }

    return parts.join(', ');
  }

  getLocationCaption(location: Location): string {
    const parts = [];

    parts.push(this.getGeoLocationCaption(location));
    if (this.isValueSet(location?.latitude)) {
      parts.push('GPS: ' + location?.latitudeDMS + ', ' + location?.longitudeDMS);
    }
    const altitude = this.getAltitude(location);
    if (this.isValueSet(altitude)) { parts.push(altitude); }
    if (this.isValueSet(location?.plant)) { parts.push(location?.plant); }
    if (this.isValueSet(location?.comments)) { parts.push(location?.comments); }
    if (this.isValueSet(location?.collectedBy)) { parts.push('leg. ' + location?.collectedBy); }
    if (this.isValueSet(location?.determinedBy)) { parts.push('desig. ' + location?.determinedBy); }
    if (this.isValueSet(location?.collection)) { parts.push('coll. ' + location?.collection); }

    return parts.join(', ');

  }

  getGPSCoordinate(coordinate: number, asNumber: boolean = false) {
    const decimalPoints = 12;
    const gpsString = coordinate.toString();
    const gpsSplitted = gpsString.split('.');

    let preDec = '0';
    if (gpsSplitted.length > 0) {
      preDec = gpsSplitted[0];
    }

    let postDec = '0';
    if (gpsSplitted.length > 1) {
      postDec = gpsSplitted[1];
    }

    const gpsFormatted = preDec + '.' + (postDec + '000000000000').substr(0, decimalPoints);
    return asNumber ? gpsFormatted as unknown as number : gpsFormatted;
  }

  getAltitude(location: Location): string {
    if (this.isValueSet(location?.altitudeFrom) || this.isValueSet(location?.altitudeTo)) {
      if (this.isValueSet(location?.altitudeFrom) && this.isValueSet(location?.altitudeTo) && location?.altitudeFrom > 0 && location?.altitudeTo > 0) {
        return location?.altitudeFrom + ' - ' + location?.altitudeTo + 'm';
      }
      if (this.isValueSet(location?.altitudeFrom) && location?.altitudeFrom > 0) {
        return location?.altitudeFrom + 'm';
      }
      if (this.isValueSet(location?.altitudeTo) && location?.altitudeTo > 0) {
        return location?.altitudeTo + 'm';
      }
    }
    return null;
  }

  getTaxonCaption(taxon: any, formatHTML: boolean = false, includeSubGenus: boolean = false) {
    let nomenclature = '';
    const blankChar = formatHTML ? '&nbsp;' : ' ';

    if (taxon?.genus && taxon?.genus !== 'null') {
      nomenclature =
        (formatHTML ? '<i>' : '') +
        taxon?.genus.charAt(0).toUpperCase() + taxon?.genus.toLowerCase().slice(1) + blankChar +
        (formatHTML ? '</i>' : '');

      if (includeSubGenus) {
        if (taxon?.subGenus && taxon?.subGenus !== 'null') {
          nomenclature += ' (' +
              (formatHTML ? '<i>' : '') +
              taxon?.subGenus.toLowerCase() +
              (formatHTML ? '</i>' : '') +
              ') ';
        }
      }

      if (taxon?.species && taxon?.species !== 'null') {
        nomenclature +=
          (formatHTML ? '<i>' : '') +
          taxon?.species.toLowerCase() + blankChar +
          (formatHTML ? '</i>' : '');
      }

      if (taxon?.subSpecies && taxon?.subSpecies !== 'null') {
        nomenclature +=
          (formatHTML ? '<i>' : '') +
          taxon?.subSpecies.toLowerCase() + blankChar +
          (formatHTML ? '</i>' : '');
      }

      if (taxon?.describedByName && taxon?.describedByName !== 'null') {

        if (taxon?.describedByOriginalSource === '1') {
          nomenclature += '(';
        }

        nomenclature += taxon?.describedByName;

        if (taxon?.describedByYear && !isNaN(taxon?.describedByYear as number) && taxon?.describedByYear.toString().length === 4) {
          nomenclature += ', ' + taxon?.describedByYear;
        }

        if (taxon?.describedByOriginalSource === '1') {
          nomenclature += ')';
        }
      }

    }

    return nomenclature.trim();
  }

  getFormattedDate(dateString: string|undefined): string {
    try {
      const date: Date = new Date(dateString);
      return date.toLocaleDateString('de-DE');

    } catch (e) {
      return '';

    }


  }

    isValueSet(value?: any): boolean {
    return value !== null &&
      value !== 'null' &&
      value !== 'undefined' &&
      typeof value !== 'undefined'
    ;

  }
}
