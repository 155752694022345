import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Taxon} from '../../entities/taxon';
import {ApiService} from '../../services/api.service';
import {NomenclatureService} from '../../services/nomenclature.service';
import {MapService} from '../../services/map.service';
import {Location} from '../../entities/taxon/location';
import {PlantStatistics} from '../../entities/plantStatistics';
import {Literature} from '../../entities/taxon/literature';
import {environment} from '../../../environments/environment';
import {Remark} from '../../entities/taxon/remark';
import {FigureTypes, File, FileTypes} from '../../entities/taxon/file';
import {TaxonService} from '../../services/taxon.service';
import {DeviceService} from '../../services/device.service';


@Component({
  selector: 'app-taxon-show',
  templateUrl: './taxon-show.component.html',
  styleUrls: ['./taxon-show.component.css']
})
export class TaxonShowComponent implements OnInit {

  @Input() taxon: Taxon = null;
  taxonNotFound: boolean = false;
  overlaysHidden: boolean = false;

  fileTypes: typeof FileTypes = FileTypes;
  figureTypes: typeof FigureTypes = FigureTypes;

  selectedImageUrl?: string;

  environment = environment;

  selectedLocation: Location = null;
  plantStatistics: PlantStatistics = null;
  literatureCollection: {
    firstDescriptions: Literature[],
    furtherReferences: Literature[]
  } = {
    firstDescriptions: [],
    furtherReferences: []
  };
  locationCollection: {
    a_locusTypicus: Location[],
    b_dnaSequences: Location[],
    c_other: Location[]
  } = {
    a_locusTypicus: [],
    b_dnaSequences: [],
    c_other: []
  };
  figuresCollection: {
    habitus: File[],
    genitalia: File[],
    identification: File[],
    biology: File[],
    dispersalArea: File[]
  } = {
    habitus: [],
    genitalia: [],
    identification: [],
    biology: [],
    dispersalArea: []
  };
  figuresCount: number = 0;

  constructor(
    readonly api: ApiService,
    readonly nomenclature: NomenclatureService,
    readonly map: MapService,
    readonly route: ActivatedRoute,
    readonly router: Router,
    readonly taxonService: TaxonService,
    readonly device: DeviceService
  ) { }


  ngOnInit(): void {

    let arrowIcon = null;
    let mapControls = null;

    $(document).on('scroll', (event, component = this) => {

      if (arrowIcon === null) {
        arrowIcon = $('.arrow-icon');
      }
      if (mapControls === null) {
        mapControls = $('.map-overlay-controls');
      }

      const mapHeightMobile = 480; // Related to CSS class .spread-top
      const opacity = ((mapHeightMobile - window.scrollY * 1.3) / mapHeightMobile);

      (arrowIcon).css({'opacity': opacity * 0.5});
      if (component.device.isMobile()) {
        (mapControls).css({'opacity': opacity});
      }

    });

    this.route.params.subscribe(params => {
      let taxonId = params['taxonId'] ? +params['taxonId'] : null;
      let version = params['version'] ? +params['version'] : null;

      if (taxonId) {
        this.api.getTaxon(taxonId, version).then(taxon => {
          this.taxon = taxon;

          if (this.taxon !== null) {
            this.taxonNotFound = false;

            this.taxonService.taxon = this.taxon;
            this.plantStatistics = new PlantStatistics(this.taxon);

            for (let literature of this.taxon.literature) {
              if (literature.literatureType === 'FD') {
                this.literatureCollection?.firstDescriptions.push(literature)
              }
              if (literature.literatureType === 'FR') {
                this.literatureCollection?.furtherReferences.push(literature)
              }
            }

            for (let location of this.taxon.locations) {
              const latLonDMS = this.taxonService.convertLatLonToDMS(location.latitude, location.longitude);

              location.latitudeDMS = latLonDMS[0];
              location.longitudeDMS = latLonDMS[1];

              location.dnaSequenceCO1Formatted = this.taxonService.formatDNASequence(location.dnaSequenceCO1);
              location.dnaSequence16SFormatted = this.taxonService.formatDNASequence(location.dnaSequence16S);
              location.dnaSequence28SFormatted = this.taxonService.formatDNASequence(location.dnaSequence28S);

              if (location.locusTypicus === 1) {
                this.locationCollection?.a_locusTypicus.push(location)
              } else if (
                  location.dnaSequenceCO1 !== null ||
                  location.dnaSequence16S !== null ||
                  location.dnaSequence28S !== null
              ) {
                this.locationCollection?.b_dnaSequences.push(location)
              } else {
                this.locationCollection?.c_other.push(location)
              }
            }

            this.map.showLocationsOnMap(this.taxon);

            this.figuresCount = 0;
            for (let file of this.taxon.files) {

              if (file.fileType === FileTypes.FIGURE) {
                switch (file.figureType) {
                  case FigureTypes.HABITUS:
                    this.figuresCollection.habitus.push(file);
                    break;

                  case FigureTypes.GENITALIA:
                    this.figuresCollection.genitalia.push(file);
                    break;

                  case FigureTypes.IDENTIFICATION:
                    this.figuresCollection.identification.push(file);
                    break;

                  case FigureTypes.BIOLOGY:
                    this.figuresCollection.biology.push(file);
                    break;

                  case FigureTypes.DISPERSALAREA:
                    this.figuresCollection.dispersalArea.push(file);
                    break;
                }
                this.figuresCount++;
              }

            }

          } else {
            this.taxonNotFound = true;
          }


          if (environment.debug) {
            console.log(this.taxon);
          }
        });
      }
    });
  }

  previewLiterature(literature: Literature) {
    if (literature?.referenceType === 'EXTERNAL') {
      window.open(literature?.reference);
    }
    if (literature?.referenceType === 'INTERNAL') {
      window.open(environment.cdnBaseUrl + '/' + literature?.reference);
    }
  }

  previewRemark(remark: Remark) {
    if (remark?.content === null && remark?.reference !== null && remark?.reference !== '') {
      window.open(remark?.reference);
    }
  }

  previewFigure(file: File) {
    if (file?.file !== null && file?.file !== '') {
      const url = environment.cdnBaseUrl + '/' + file?.file;
      if (this.device.isMobile()) {
        window.open(url);
      } else {
        this.selectedImageUrl = null;
        this.selectedImageUrl = undefined;
        this.selectedImageUrl = url;
      }
    }
  }

  isNothing(value): boolean {
    return value === null ||
      value.toString().trim() === 'null' ||
      value.toString().trim() === '';
  }

  toggleOverview() {
    const mapOverlay = $('.map-overlay-hideable');
    const animationTime: number = 500;

    if ($(mapOverlay).css('display') === 'none') {
      $('.map-overlay-controls').fadeTo(animationTime, 1);
      $(mapOverlay).fadeIn(animationTime);
      $('.flaticon-eye-open').addClass('flaticon-eye-closed').removeClass('flaticon-eye-open');
      this.map.fitBounds();
    } else {
      $('.map-overlay-controls').fadeTo(animationTime, 0.5);
      $(mapOverlay).fadeOut(animationTime);
      $('.flaticon-eye-closed').addClass('flaticon-eye-open').removeClass('flaticon-eye-closed');
      this.map.fitBounds(0, 0);
    }

  }

  changeVersion(version: number) {
    this.router.navigate(['/taxon/show/' + this.taxonService.taxon.taxonId + '/' + version]);
    // @ts-ignore
    $('#collapseHistoricalData').collapse('hide');
  }

  linkThisSite() {
    navigator.clipboard.writeText(window.location.href).then(() => {
      //$('#linkThisSiteSuccess').stop().fadeIn().delay(4000).fadeOut();
    });
  }


}

$(function() { // let all dom elements are loaded
  $(document).on(
      'show.bs.collapse',
      '#collapseHistoricalData',
      function(e) {
        $('.map-canvas').addClass('map-canvas-inactive-dark');
      });

  $(document).on(
      'hide.bs.collapse',
      '#collapseHistoricalData',
      function(e) {
        $('.map-canvas').removeClass('map-canvas-inactive-dark');
      });

  // @ts-ignore
  $('[data-toggle="popover"]').popover()
    .click(function () {
      setTimeout(function() {
        // @ts-ignore
        $('[data-toggle="popover"]').popover('hide');
      }, 3000);
    });

});
