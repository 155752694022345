import { Component, OnInit } from '@angular/core';
import {MapService} from "../../services/map.service";
import {TaxonService} from "../../services/taxon.service";

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  constructor(
    readonly map: MapService,
    readonly taxonService: TaxonService
  ) { }

  ngOnInit(): void {
    this.map.init();

  }
}
