import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {


  constructor() { }

  isMobile(): boolean {
    const breakpointWidth = parseInt(
        getComputedStyle(document.body)
            .getPropertyValue('--breakpoint-sm')
            .replace('/px/g', '')
    );


    const deviceWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;

    return deviceWidth <= breakpointWidth;

  }

}
