 export enum FileTypes {
   FIGURE = 'FIGURE'
 }

 export enum FigureTypes {
   HABITUS = 'HABITUS',
   GENITALIA = 'GENITALIA',
   IDENTIFICATION = 'IDENTIFICATION',
   BIOLOGY = 'BIOLOGY',
   DISPERSALAREA = 'DISPERSALAREA'
 }

 export class File {
  id: number = null;
  taxonId: number = null;
  version: number = null;
  title: string = null;
  file: string = null;
  fileType: FileTypes = null;
  figureType: FigureTypes = null;
  dateCreated: string = null;
  dateUpdated: string = null;
  dateReleased: string = null;
  dateExpires: string = null;
  status: string = null;
}
