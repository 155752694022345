import {Location} from "./taxon/location";
import {Taxon} from "./taxon";

export class PlantStatistics {
  maxQuantity: number = 0;
  statistics: [{
    plantName: string,
    quantity: number
  }];
  statisticsSorted = [];

  statistics2: {} = {};

  constructor(taxon: Taxon = null) {
    if (taxon !== null) {
      for (let location of taxon.locations) {
        this.add(location);
      }

      for (let statistic in this.statistics2) {
        this.statisticsSorted.push([statistic, this.statistics2[statistic]]);
      }

      this.statisticsSorted.sort(function(a, b) {
        return b[1] - a[1];
      });
    }
  }

  add(location: Location) {
    // this.statistics.push(
    //   {
    //     plantName: location.plant,
    //     quantity: location.quantity
    //   }
    // );

    if (location?.plant !== null && location?.plant.trim() !== 'null') {
      if (!this.statistics2.hasOwnProperty(location?.plant)) {
        this.statistics2[location.plant] = 0;
      }
      this.statistics2[location.plant] += location?.quantity;
      this.maxQuantity += location.quantity;
    }
  }

  getPlantsByQuantity() {
    return this.statisticsSorted;
  }
}
