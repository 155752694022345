<div [ngClass]="{'spread-top d-block d-sm-none': taxonService.taxonsGrouped !== null, 'd-none': taxonService.taxonsGrouped === null}"></div>

<div class="row p-1 p-sm-3 p-md-3">

  <div class="content-container col-3 col-xl-2 col-lg-2 col-md-3 col-sm-1"  [class.d-none]="taxonService.taxonsGrouped !== null"></div>

  <div
      [ngClass]="{'order-2 order-sm-1 order-md-1 order-lg-1 order-sm-2': taxonService.taxonsGrouped !== null, 'd-none': taxonService.taxonsGrouped === null}"
      class="content-container map-overlay col-12 col-xl-3 col-lg-3 col-md-6 col-3">

    <!-- Taxon List -->
    <div class="list-group p-0 taxon-list bg-black-blur-100 rounded shadow-dark-lg">
        <div class="list-group-item border-0">
            <div class="row">
                <div class="col-8">
                    <h5 [class.d-none]="searchService.resultCount > 0">List of species</h5>
                    <h5 [class.d-none]="searchService.resultCount <= 0">Search results</h5>
                </div>
                <div class="col-4 text-right">
                    <div [class.d-none]="taxonService.taxons.length > 0 && !searchService.isSearchRunning" class="spinner float-right">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                </div>
            </div>
          <div class="row">
            <div class="col">

              <div [class.d-none]="searchService.resultCount > 0 || searchService.isSearchRunning">
                This catalogue contains {{ taxonService.taxons.length | number }} entries and
                {{ taxonService.locationsCount | number }} datasets of localities
              </div>
              <div [class.d-none]="searchService.resultCount <= 0 || searchService.isSearchRunning">
                {{ taxonService.taxons.length | number }} Results for '{{ searchService.query }}'
              </div>

            </div>
          </div>
          <div class="badge badge-danger font-weight-normal d-none" [class.d-inline]="searchService.query !== null && searchService.query !== '' && searchService.query.length < searchService.minQueryLength">
            Please enter at least {{ searchService.minQueryLength }} characters.
          </div>
          <div class="badge badge-warning font-weight-normal d-none" [class.d-inline]="searchService.query !== null && searchService.query !== '' && searchService.query.length >= searchService.minQueryLength && searchService.resultCount !== null && searchService.resultCount <= 0">
            Sorry, no results for <strong>{{ searchService.query }}</strong>.
          </div>

        </div>
        <div *ngFor="let taxonGroup of taxonService.taxonsGrouped | keyvalue">
            <div *ngIf="taxonGroup.value.length > 0">
                <h5 class="list-group-item m-0 p-0 pl-3 pt-3 rounded-0">{{ taxonGroup.key }}</h5>
                <button
                    type="button"
                    class="list-group-item rounded-0 list-group-item-action p-1 pl-4"
                    *ngFor="let taxon of taxonGroup.value" [innerHTML]="nomenclature.getTaxonCaption(taxon, true)"
                    [routerLink]="['/taxon/show/' + taxon.taxonId]"
                ></button>
            </div>
        </div>

    </div>
  </div>


  <div
      class="content-container map-overlay col-12 col-xl-8 col-lg-8  col-sm-10 col-auto"
      [ngClass]="{'order-1 order-sm-2 order-md-1 order-lg-2 order-sm-1 col-md-6': taxonService.taxonsGrouped !== null, 'col-md-12': taxonService.taxonsGrouped === null}"
  >
    <a [ngClass]="{'d-block d-sm-none arrow-icon open': taxonService.taxonsGrouped !== null, 'd-none': taxonService.taxonsGrouped === null}">
      <span class="left-bar"></span>
      <span class="right-bar"></span>
    </a>

    <div class="mb-5 mt-5" [class.d-none]="taxonService.taxonsGrouped !== null">
        <div class="bg-black-blur-100 rounded shadow-danger-lg p-3 text-center bg-danger">
            Unfortunately, this catalogue has no data.
        </div>
    </div>

  </div>

  <div class="content-container col-3 col-xl-2 col-lg-2 col-md-3 col-sm-1"  [class.d-none]="taxonService.taxonsGrouped !== null"></div>
</div>
