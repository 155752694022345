import {Injectable} from '@angular/core';
import {Taxon} from '../entities/taxon';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class TaxonService {

  taxon: Taxon = null;
  taxons: Taxon[] = [];
  taxonsGrouped: {} = {};
  locationsCount: number = 0;


  constructor(
      readonly api: ApiService
  ) { }

  getTaxons(): Promise<any> {
    return this.api.getTaxons();
  }

  getLocationsCount(): Promise<any> {
    return this.api.getLocationsCount();
  }

  groupTaxons() {
    let taxonsGrouped = [];
    if (this.taxons !== null) {
      this.taxonsGrouped = [];
      for (let taxon of this.taxons) {

        if (!taxonsGrouped[taxon?.genus]) {
          taxonsGrouped[taxon?.genus] = [];
        }
        taxonsGrouped[taxon?.genus].push(taxon);
      }
    }
    this.taxonsGrouped = taxonsGrouped;
    return taxonsGrouped;
  }

  convertLatLonToDMS(latDecimal, lonDecimal): string[] {
    let latAbs = Math.abs(Math.round(latDecimal * 1000000.));
    if (latAbs > (90 * 1000000)) latAbs = 0;

    let lonAbs = Math.abs(Math.round(lonDecimal * 1000000.));
    if (lonAbs > (180 * 1000000)) lonAbs = 0;

    return [
      TaxonService.convertDecimalToDMS(latAbs, latDecimal < 0 ? -1. : 1.),
      TaxonService.convertDecimalToDMS(lonAbs, lonDecimal < 0 ? -1. : 1.)
    ];
  }

  private static convertDecimalToDMS(value: number, sign: number): string {
    return (
      (Math.floor(value / 1000000) * sign) + '° ' +
      Math.floor(((value / 1000000) - Math.floor(value / 1000000)) * 60) + '\' ' +
      (Math.floor(((((value / 1000000) - Math.floor(value / 1000000)) * 60) - Math.floor(((value / 1000000) - Math.floor(value / 1000000)) * 60)) * 100000) * 60 / 100000) + '\"'
    )
  }

  formatDNASequence(dnaSequence: string): string {
    const colorizeDnaSequence = false;
    const dnaSequenceColors = {
      A: 'green',
      T: 'red',
      G: 'black',
      C: 'blue'
    };
    if (dnaSequence?.startsWith('http')) {
      return '<a class="btn-link text-white" href="' + dnaSequence + '" target="_blank" rel="noopener">' + dnaSequence + '</a>' + '<span class="flaticon-link-external icon-16 ml-3"></span>';
    }
    if (dnaSequence?.replace(/[ATGC]/g, '').trim() === '') {
      let startingCharCounter = 1;
      const result = [];
      const lines = dnaSequence.match(/.{1,60}/g);

      result.push('<pre class="text-white">');
      result.push('<table>');

      for (let i = 0; i <= lines.length - 1; i++) {

        result.push('<tr><td class="unselectable pr-2 text-right">');
        result.push(startingCharCounter);
        result.push('</td><td>');

        const groups = lines[i].match(/.{1,6}/g)
        for (let j = 0; j <= groups.length - 1; j++) {
          let sequencePart = groups[j];
          if (colorizeDnaSequence) {
            sequencePart = sequencePart.replace(/A/g, '<span class="dnaSequenceColors-A">A</span>');
            sequencePart = sequencePart.replace(/T/g, '<span class="dnaSequenceColors-T">T</span>');
            sequencePart = sequencePart.replace(/G/g, '<span class="dnaSequenceColors-G">G</span>');
            sequencePart = sequencePart.replace(/C/g, '<span class="dnaSequenceColors-C">C</span>');
          }

          result.push('<span class="ml-2">' + sequencePart + '</span>');
        }
        result.push('</td></tr>');

        startingCharCounter += 60;

      }

      result.push('</table>');
      result.push('</pre>');

      return result.join('');
    }
    return dnaSequence;
  }


}
