import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Taxon } from '../../entities/taxon';
import { NomenclatureService } from '../../services/nomenclature.service';
import { MapService } from '../../services/map.service';
import { TaxonService } from '../../services/taxon.service';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-taxon-list',
  templateUrl: './taxon-list.component.html',
  styleUrls: ['./taxon-list.component.css']
})
export class TaxonListComponent implements OnInit {

  taxons: Taxon[] = [];

  searchAutocompleteData: [
    {
      taxonomy: string
    }
  ] = [{taxonomy: ''}];
  keyword = 'taxonomy'; itemTemplate; notFoundTemplate;
  selectEvent(item) {
    this.searchService.search(item.taxonomy);
  }
  onChangeSearch(event) { }
  onFocused(event) { }

  constructor(
    readonly api: ApiService,
    readonly nomenclature: NomenclatureService,
    readonly map: MapService,
    readonly taxonService: TaxonService,
    readonly searchService: SearchService
  ) {

  }

  ngOnInit(): void {


    this.map.clearMarkers();
    this.taxonService.taxon = null;
    if (this.taxonService.taxons?.length <= 0) {
      this.taxonService.getLocationsCount().then(locationsCount => {
        this.taxonService.locationsCount = locationsCount;
      });
      this.taxonService.getTaxons().then(taxons => {
        this.taxonService.taxons = taxons;
        this.taxonService.groupTaxons();


        let autocompleteData = [];
        let autocompleteDataProjection = [];


        for (let taxonomyData of this.taxonService.taxons) {
          if (taxonomyData.genus !== null) {autocompleteData.push(taxonomyData.genus)};
          if (taxonomyData.subGenus !== null) {autocompleteData.push(taxonomyData.subGenus)};
          if (taxonomyData.species !== null) {autocompleteData.push(taxonomyData.species)};
          if (taxonomyData.subSpecies !== null) {autocompleteData.push(taxonomyData.subSpecies)};
        }

        autocompleteData.sort();

        for (let t of [...new Set(autocompleteData)]) {
          autocompleteDataProjection.push({taxonomy: t});
        }

        // @ts-ignore
        this.searchAutocompleteData = autocompleteDataProjection;

      });

    }
  }

}
