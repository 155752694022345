import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {MapService} from "../../services/map.service";
import {TaxonService} from "../../services/taxon.service";
import {Router} from "@angular/router";
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  applicationTitle = environment.applicationTitle;
  applicationSubTitle = environment.applicationSubTitle;
  environment = environment;
  overlaysHidden: boolean = false;

  constructor(
    readonly map: MapService,
    readonly taxonService: TaxonService,
    readonly router: Router,
    readonly searchService: SearchService,
  ) { }

  ngOnInit(): void {
  }

  search(query: string) {
    this.searchService.search(query);
    this.router.navigate(['/taxons']);
  }

}
